<template>
  <fw-panel :counter="vacations ? vacations.length : 0" boxed="xs" :title="`Trabalhadores de férias em ${date}`">
    <LoadingPlaceholder v-if="loading" list />
    <RecycleScroller
      v-else-if="vacations && vacations.length > 0"
      v-slot="{ item }"
      key-field="key"
      :items="vacations"
      :item-size="50"
      :buffer="50"
      class="scroller fw-scrollbar"
    >
      <div
        class="rounded-lg px-2 py-1.5 text-sm font-semibold text-white bg-yellow-800 bg-opacity-10 flex items-center justify-between gap-2 flex-1"
      >
        <fw-person size="sm" no-style :person="item.user" paddingless custom-class="text-gray-800" :clickable="false">
          <template #secondline>
            <div class="text-sm opacity-80 font-medium">
              {{ item.day_type == 'allday' ? 'Todo o dia' : 'Meio dia' }}
            </div>
          </template>
        </fw-person>
      </div>
    </RecycleScroller>
  </fw-panel>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    RecycleScroller,
    LoadingPlaceholder,
  },

  props: {
    date: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      vacations: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },
  },

  mounted() {
    this.getUserDayEvents(this.date)
  },

  methods: {
    async getUserDayEvents(date) {
      this.loading = true

      console.log('getUserDayEvents for :>> ', date)

      await utils.tryAndCatch(this, async () => {
        const data = await this.api.getManagerVacationsEvents({ date })
        console.log('data :>> ', data)
        this.vacations = data
      })

      this.loading = false
    },
  },
}
</script>

<style scoped>
.scroller {
  height: 100%;
  max-height: 80vh;
}
</style>
