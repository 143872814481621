<template>
  <fw-layout wide mobile-ready :back-to-enable="false" footer management back-to-home="/manage">
    <template #header-nav>
      <HeaderSwitchManage />
    </template>

    <template #main-content>
      <PanelUCIdHero :user="user" class="mt-4 mb-10" management />

      <fw-panel title="Área de gestão" class="my-5">
        <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
          <button-quick-access
            v-if="userPermissions.isManager || userPermissions.isMapsManager || userPermissions.isAdmin"
            label="Gestão"
            title="Férias"
            icon="calendar-event"
            type="yellow"
            @clicked="$router.push('/manage/vacations')"
          />
          <button-quick-access
            v-if="debugmode && (userPermissions.isManager || userPermissions.isMapsManager || userPermissions.isAdmin)"
            label="Gestão"
            title="Marcações"
            icon="clock-line"
            type="yellow"
            @clicked="$router.push('/manage/attendances')"
          />
          <button-quick-access
            v-if="userPermissions.isManager || userPermissions.isAdmin"
            :label="$t('audit')"
            :title="$t('activity')"
            icon="activity"
            type="yellow"
            @clicked="$router.push('/manage/activity')"
          />
          <button-quick-access
            v-if="userPermissions.isManager || userPermissions.isAdmin"
            :label="$t('audit')"
            :title="$t('notifications')"
            icon="message-sent"
            type="yellow"
            @clicked="$router.push('/manage/notifications')"
          />
          <button-quick-access
            v-if="userPermissions.isEnrollmentsManager || userPermissions.isAdmin || userPermissions.isManager"
            label="Formação"
            title="Cursos"
            icon="award"
            type="yellow"
            @clicked="$router.push('/manage/courses')"
          />
        </div>
      </fw-panel>

      <fw-panel v-if="!canOnlySeeEnrollments" title="Inbox" class="my-5">
        <template #default>
          <PanelManageInboxResume />
        </template>
      </fw-panel>

      <WeeklyEventsPanel
        v-if="!canOnlySeeEnrollments"
        class="my-5"
        :loading="loading.weeksPanel"
        :weeks="weeks"
        @get-week-events="getUserWeekEvents"
      >
        <template #events="{events}">
          <div v-for="(evt, ee) in events" :key="'event_' + ee" class="flex flex-col">
            <!-- Holidays -->
            <div
              v-if="evt.type == 'birthday'"
              class="eventbox bg-primary bg-opacity-10 flex items-center justify-between gap-2 flex-1"
            >
              <fw-person
                size="sm"
                no-style
                :clickable="false"
                :person="evt.user"
                paddingless
                custom-class="text-gray-800"
              >
                <template #secondline>
                  <div class="text-sm opacity-80 font-medium">
                    Aniversário
                  </div>
                </template>
              </fw-person>
            </div>

            <div
              v-else-if="evt.type == 'holiday'"
              class="eventbox bg-gray-400 text-white flex flex-col flex-1 items-center justify-center font-bold"
            >
              <div>Feriado</div>
            </div>

            <div
              v-else-if="evt.type == 'vacation' && evt.user"
              class="eventbox bg-yellow-800 bg-opacity-10 flex items-center justify-between gap-2 flex-1"
            >
              <fw-person
                size="sm"
                no-style
                :person="evt.user"
                paddingless
                custom-class="text-gray-800"
                :clickable="true"
                @clicked="gotToUserMap(evt)"
              >
                <template #secondline>
                  <div class="text-sm opacity-80 font-medium">
                    Férias | {{ evt.day_type == 'allday' ? 'Todo o dia' : 'Meio dia' }}
                  </div>
                </template>
              </fw-person>
            </div>
            <div v-else-if="evt.type == 'vacations_count'" class="-my-1">
              <fw-button type="link-muted" expanded @click.native="openVacationsModal(evt)">
                + {{ evt.total - 10 }} pessoas de férias
              </fw-button>
            </div>
          </div>
        </template>
      </WeeklyEventsPanel>

      <fw-panel-info label="Data (raw)" debug class="my-5">
        <json-viewer :value="{ stats, weeks }"></json-viewer>
      </fw-panel-info>
    </template>

    <template #modals>
      <fw-modal
        :active="vacationsModalOpen"
        size="xl"
        width="44rem"
        :height-fit-screen="true"
        @close="closeVacationsModal"
      >
        <ModalDayVacationEvents v-if="selectedDate" :date="selectedDate" @close="closeVacationsModal" />
        <template #footer>
          <fw-button type="black" expanded @click.native="closeVacationsModal">Fechar</fw-button>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import HeaderSwitchManage from '@/components/header/HeaderSwitchManage'
import PanelManageInboxResume from '@/components/panels/manage/PanelManageInboxResume'
import WeeklyEventsPanel from '@/fw-modules/fw-core-vue/ui/components/panels/WeeklyEventsPanel.vue'
import ModalDayVacationEvents from '@/components/modals/ModalDayVacationEvents'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ButtonQuickAccess,
    PanelUCIdHero,
    HeaderSwitchManage,
    WeeklyEventsPanel,
    PanelManageInboxResume,
    ModalDayVacationEvents,
  },

  data() {
    return {
      stats: [],
      weeks: {},
      unreadMessages: [],
      pendingActions: [],
      vacationsModalOpen: false,
      selectedDate: null,
      loading: {
        stats: true,
        messages: true,
        pendingActions: true,
        weeksPanel: true,
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    },

    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    canOnlySeeEnrollments() {
      return (
        this.userPermissions.isEnrollmentsManager &&
        !this.userPermissions.isAdmin &&
        !this.userPermissions.isManager &&
        !this.userPermissions.isMapsManager
      )
    },
  },

  mounted() {
    if (!this.canOnlySeeEnrollments) {
      this.loadStats()
    }
  },

  methods: {
    loadStats() {
      // Dummy, for now
      this.stats = []
      this.loading.stats = false
    },

    async getUserWeekEvents(weekNumber, year) {
      this.loading.weeksPanel = true

      console.log('getUserWeekEvents for :>> ', weekNumber, year)

      await utils.tryAndCatch(this, async () => {
        const data = await this.api.getManagerWeekEvents(weekNumber, year)
        this.weeks = Object.assign({}, this.weeks, data.weeks)
      })

      this.loading.weeksPanel = false
    },

    gotToUserMap(evt) {
      console.log('gotToUserMap', evt)
      const date = new Date(`${evt.date}T00:00:00`)
      this.$router.push({
        name: 'manage-map-user',
        params: { userKey: evt.user.key, year: date.getFullYear(), key: evt.map_key, mapUserKey: evt.map_user_key },
      })
    },

    openVacationsModal(evt) {
      console.log('openVacationsModal', evt)
      this.vacationsModalOpen = true
      this.selectedDate = evt.date
    },

    closeVacationsModal() {
      this.vacationsModalOpen = false
      this.selectedDate = null
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "quickAccess": "Acesso rápido",
    "audit": "Auditoria",
    "activity": "Atividade",
    "notifications": "Notificações",
    "maps": "Mapas"
  },
  "en": {
    "quickAccess": "Quick access",
    "audit": "Audit",
    "activity": "Activity",
    "notifications": "Notifications",
    "maps": "Maps"
  }
}
</i18n>
